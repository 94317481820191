import { Maybe } from '@lib/utility-types';

async function callApiRoutes<T = any>(url: string): Promise<T>;
async function callApiRoutes<T = any>(
  url: string,
  options: Maybe<RequestInit>,
  failSilently: true
): Promise<T | object>;
async function callApiRoutes<T = any>(url: string, options?: Maybe<RequestInit>, failSilently?: false): Promise<T>;
async function callApiRoutes(url: string, options?: RequestInit, failSilently = false) {
  const baseOptions = { method: 'GET' };
  const res = await fetch(url, { ...baseOptions, ...options });
  const response = res.headers.get('content-type')?.includes('application/json') ? await res.json() : {};
  if (res.ok) {
    return response;
  }
  return failSilently ? {} : Promise.reject(response);
}

export default callApiRoutes;
