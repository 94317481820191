import React, { RefObject, useEffect, useRef } from 'react';
import { tabbable } from 'tabbable';

interface Props {
  children: React.ReactNode;
  focusFirst?: boolean;
}

export default function FocusTrap({ children, focusFirst = false }: Props) {
  const root: RefObject<HTMLDivElement> = useRef(null);
  const anchor: RefObject<Element | null> = useRef(document.activeElement);
  // Restore focus to the previously active element
  const returnFocus = () => {
    if (anchor.current instanceof HTMLElement) {
      anchor.current.focus();
    }
  };

  //Select first focusable element
  const selectFirstFocusableEl = () => {
    if (!root.current) return;
    const focusableEls = tabbable(root.current);

    if (focusableEls.length > 0) {
      focusableEls[0].focus();
    }
  };

  useEffect(() => {
    if (!root.current) return;

    const observer = new MutationObserver(() => {
      setTimeout(() => {
        if (focusFirst) {
          selectFirstFocusableEl();
          observer.disconnect();
        }
      }, 50);
    });

    observer.observe(root.current, { childList: true, subtree: true });

    // Initial focus attempt
    if (focusFirst) {
      setTimeout(selectFirstFocusableEl, 50);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
      returnFocus();
    };
  }, [focusFirst]);

  return (
    <div ref={root} className="outline-none focus-trap" tabIndex={-1}>
      {children}
    </div>
  );
}
