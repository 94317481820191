import Link from 'next/link';
import { useRouter } from 'next/router';

import { renderImage } from '@lib/image';
import { FCWithChildren } from '@lib/types/react-utilities';

import s from './CompactNavbar.module.scss';

export const CompactNavbar: FCWithChildren = ({ children }) => {
  const { query } = useRouter();

  return (
    <header className={s.header}>
      <div className={`${s.headerWrapper} container px-4 lg:px-6`}>
        <Link href="/" title="home" style={query?.inapp ? { pointerEvents: 'none' } : undefined} className="max-h-full">
          {renderImage(
            { url: '/svgs/life360-tile-logo-mobile.svg' },
            { 'aria-hidden': true, className: s.logo, height: 50, width: 70 }
          )}
        </Link>

        {children}
      </div>
    </header>
  );
};
